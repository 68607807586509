.title{
    height:30px;
    // border: 1px solid red;
}
.pager{
    height: 30px;
    // border:1px solid red; 
    position: absolute;
    bottom: 1.5rem;
    right: 1.5rem;
    @media screen and (max-width: 1200px) {
        bottom: 6rem;
      }
}