@use "../../scss/variables.scss" as vars;

.btnSearchMethod {
  height: 30px;
  border-radius: 20px;
  border: 1px solid black;
}
.btnActive {
  background-color: black;
  color: white;
}

input {
  height: 30px;
  padding-left: 1.5rem;
  border-radius: 20px;
  outline: none;
  border: 1px solid black;
}

.btnSearch {
  height: 30px;
  border-radius: 20px;
//   border: 1px solid black;
  background-color: vars.$color-dlcGreen;
}
